import { faker } from '@faker-js/faker'
import { TopupWalletVoucher, Wallet } from '@t16t/sdk';
import { sleep } from '../../utils';

type ApiResponse<T> = {
    errorMessage: string | undefined;
    data: T | undefined;
}

export class FakeBillingService {

    async fetchWallet(): Promise<ApiResponse<Wallet>> {
        await sleep(500);
        const chanceOfFailure = Math.round(Math.random()) === 1;
        const data = chanceOfFailure? undefined : await this.generateFakeWallet();
        return {
            errorMessage: chanceOfFailure? 'Something went wrong' : undefined,
            data
        }
    }

    async topupWallet(data: TopupWalletVoucher): Promise<ApiResponse<void>> {
        await sleep(500);
        return {
            errorMessage: data.data.voucherSecretNumber === "0000000000000000"? "Failed transaction" : undefined,
            data: undefined
        }
    }

    private generateFakeWallet(): Wallet {
        return {
          entityId: faker.string.uuid(),
          balance: {
            value: faker.number.int({ min: 10, max: 1000 }),
            scale: faker.number.int({ min: 0, max: 3 }),
            currency: 'LYD',
          },
        }
      }
    
}